.bg {
  height: 110px;
  background-color: var(--darkgrey-color);
  background-image: url('img/titlebg.jpg');
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
  overflow: hidden;
}

.title {
  padding-top: 35px;
  color: var(--white-color);
  font-size: 33px;
  text-align: center;
}