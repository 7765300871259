.mainmenu {
  position: relative;
  padding-left: 35px;
  color: var(--black-color);
  text-align: left;
  text-transform: uppercase;
  font-weight: 700;
  height: 30px;
  line-height: 30px;
}

.mainmenu::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  background-image: url('./img/menu_logo.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.menutitle {
  cursor: pointer;
}

.menulist {
  position: relative;
  display: block;
  width: 250px;
  background-color: var(--green-color);
  text-align: center;
  padding: 15px 5px;
  z-index: 100;
  border-radius: 10px;
}

.menuitem {
  color: var(--white-color);
  font-size: 16px;
}

.menuitem:hover {
  text-decoration: underline;
  color: var(--green-color);
  background-color: var(--white-color);
  border-radius: 10px;
}

.menuitem>a {
  display: block;
  width: 100%;
}