.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  z-index: 100;
  background-color: #333;
  opacity: 0.75;
}

.inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 30px;
  z-index: 200;
}

.container {
  max-width: 840px;
  margin: 0px auto;
  padding: 0px 15px;
  text-align: center;
}

.body {
  position: relative;
  width: 100%;
  background-color: var(--white-color);
  margin: 0 auto;
  padding: 20px;
  min-height: 100px;
  border-radius: 20px;
  z-index: 200;
}

.cross {
  font-size: 24px;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.cross:hover {
  color: var(--darkgrey-color);
}

.title {
  padding: 5px 30px 20px 30px;
  font-size: 26px;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image {
  position: relative;
  max-width: 300px;
}

.flag {
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: auto;
}

.infoBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.h4 {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 10px;
  color: var(--grey-color);
}

.h4 span {
  color: var(--darkgrey-color);
}

.modernInfo {
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  font-size: 16px;
}

.modernTitle {
  grid-column: 1 / 12;
  font-size: 20px;
  margin-bottom: 20px;
}

.modernNull {
  grid-column: 1 / 2;
  grid-row: 2 / 4;
}

.modernLevel1 {
  grid-column: 2 / 4;
}

.modernLevel2 {
  grid-column: 4 / 6;
}

.modernLevel3 {
  grid-column: 6 / 8;
}

.modernLevel4 {
  grid-column: 8 / 10;
}

.modernLevel5 {
  grid-column: 10 / 12;
}

.bottomBorder {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--grey-color);
}

.rightBorder {
  border-right: 1px solid var(--grey-color);
}

.equipmentInfo {
  width: 100%;
}

.equipmentTitle {
  padding: 20px 0px 10px 0px;
}

.equipmentBody {
  display: flex;
  font-size: 14px;
}

.equipmentSubtitle {
  font-size: 15px;
  font-weight: 700;
}

.equipmentStreamerTitle {
  flex: 0 0 35%;
}

.equipmentVariantTitle {
  flex: 0 0 15%;
}

.equipmentSlotTitle {
  flex: 1 1 70px;
}

@media (min-width: 768px) {
  .info {
    flex-direction: row;
  }
}