.footer {
  width: 100%;
  background-color: var(--black-color);
  color: var(--grey-color);
  padding: 30px 0px;
  font-size: 14px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.column {
  width: 100%;
  height: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--lightgrey-color);
}

@media (min-width: 577px) {
  .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .column {
    width: 100%;
    padding-bottom: 15px;
    padding-right: 10px;
    border-right: 1px solid var(--grey-color);
    border-bottom: none;
  }

  .column:nth-child(even) {
    border: none;
  }
}

@media (min-width: 992px) {
  .container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }

  .column {
    padding-right: 30px;
    border-right: 1px solid var(--grey-color);
    border-bottom: none;
  }

  .column:nth-child(even) {
    border-right: 1px solid var(--grey-color);
  }
}

.column:last-child {
  border: none;
  padding-right: 0px;
}

.title {
  color: var(--white-color);
  padding-bottom: 15px;
  font-size: 20px;
}

.list {
  line-height: 20px;
}

.text {
  line-height: 1.5;
  margin-bottom: 20px;
}

.link {
  color: var(--green-color);
}

.link:hover {
  color: var(--grey-color);
}

.copyright {
  margin-top: 20px;
  padding: 20px 0px 0px 0px;
  border-top: 1px solid var(--grey-color);
  text-align: center;
  font-size: 14px;
}

.copyright a:hover {
  font-weight: 700;
  color: var(--white-color);
}