.cell {
  font-size: 14px;
  padding: 5px;
  border-bottom: 1px solid var(--lightgrey-color);
}

.rightBorder {
  border-right: 1px solid var(--lightgrey-color);
}

.image {
  width: 20px;
  height: 20px;
}

.noinfo {
  grid-column: 1/12;
  padding: 10px 0px;
}