.header {
  width: 100%;
  padding: 30px 0px;
  background-color: var(--white-color);
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu {
  flex-basis: 33.3333%;
}

.title {
  flex-basis: 33.3333%;
  font-size: 36px;
  text-align: center;
  color: var(--title-color);
}

.search {
  flex-basis: 33.3333%;
  text-align: right;
}