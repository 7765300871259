.body {
  display: flex;
  flex-direction: column;
  padding: 30px 0px;
}

.text {
  font-size: 16px;
  text-align: left;
  line-height: 1.5;
  text-indent: 30px;
}

.list {
  display: flex;
  flex-direction: column;
  margin: 30px 0px;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--grey-color);
  margin-bottom: 20px;
}

.item:last-child {
  border-bottom: none;
}

.item:nth-child(even) {
  flex-direction: column-reverse;
}

.itemTitle {
  text-align: left;
  margin-bottom: 20px;
}

.image {
  width: 200px;
  height: 200px;
}

@media (min-width: 758px) {

  .item,
  .item:nth-child(even) {
    flex-direction: row;
    align-items: flex-start;
  }
}