.slider {
  width: 100%;
  background-color: var(--black-color);
}

.inner {
  width: 100%;
  height: 510px;
  background-image: url('./img/slide-1.jpg');
  background-repeat: no-repeat;
  background-position: center;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0px 13%;
}

.title {
  font-size: 36px;
  text-align: center;
  color: var(--white-color);
  margin-bottom: 50px;
}

.text {
  color: var(--white-color);
  text-align: center;
  font-size: 15px;
  line-height: 2;
  margin-bottom: 30px;
}

.btn {
  color: var(--white-color);
  background-color: inherit;
  border-color: var(--white-color);
}

.btn:hover {
  background-color: var(--green-color);
  border-color: var(--green-color);
}