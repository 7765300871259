.itemElem {
  max-width: 390px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
}

.itemElem:hover {
  cursor: pointer;
  color: var(--white-color);
  background-color: var(--green-color);
  border-radius: 5px;
}

.itemImage {
  width: 30px;
  height: auto;
  margin-right: 10px;
}

.selectedElem {
  max-width: 390px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--white-color);
  background-color: var(--green-color);
  border-radius: 5px;
}

.selectedElem:hover {
  color: var(--white-color);
  background-color: var(--lightblue-color);
}