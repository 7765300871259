.list {
  width: 100%;
}

.listItem {
  width: 100%;
  padding: 5px;
  border: 1px solid;
  border-color: var(--lightgrey-color);
}

.listItem:hover {
  background-color: var(--green-color);
  color: var(--white-color);
  cursor: pointer;
}

.listItem:hover .infoPremYes {
  color: var(--white-color);
}

.info {
  display: flex;
  width: 100%;
  gap: 5px;
  font-size: 14px;
  align-items: center;
}

.info>li {
  padding-right: 10px;
  border-right: 1px solid var(--lightgrey-color);
}

.info>li:last-child {
  border-right: none;
}

.infoLevel {
  width: 35px;
}

.infoName {
  flex-grow: 1;
  min-width: 210px;
}

.infoType {
  width: 150px;
}

.infoRole {
  width: 250px;
  ;
}

.infoImage {
  width: 30px;
  height: auto;
  margin-right: 10px;
}

.infoPremYes {
  width: 75px;
  color: var(--green-color);
  font-weight: 600;
}

.infoPremNo {
  width: 75px;
}

.infoReward {
  width: 100px;
}