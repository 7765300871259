.item {
  display: flex;
  margin-bottom: 10px;
  gap: 10px;
  font-size: 14px;
}

.item a {
  flex-basis: 50%;
  color: var(--lightgrey-color);
  overflow: hidden;
}

.item a:hover {
  color: var(--green-color);
}

.item img {
  transform: scale(1);
  transition: all 0.3s ease 0.1s;
}

.item img:hover {
  transform: scale(1.15);
}