.body {
  display: flex;
  font-size: 14px;
}

.cell {
  font-size: 14px;
  padding: 5px;
  border-bottom: 1px solid var(--lightgrey-color);
}

.rightBorder {
  border-right: 1px solid var(--lightgrey-color);
}

.image {
  width: 40px;
  height: auto;
}

.noinfo {
  font-size: 15px;
  padding: 10px 0px;
}

.streamer {
  flex: 0 0 35%;
}

.variant {
  flex: 0 0 15%;
}

.slot {
  flex: 1 1 70px;
}