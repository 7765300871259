.item {
  height: 420px;
  overflow: hidden;
  border-radius: 5px;
  background-color: var(--white-color);
}

.content {
  width: 100%;
}

.item:hover .content {
  transform: translateY(-50px);
}

.item:hover .text {
  opacity: 1;
}

.item:hover .button {
  opacity: 1;
}

.big,
.small {
  position: relative;
}

.category {
  position: absolute;
  display: block;
  top: 10px;
  left: 10px;
  background-color: var(--green-color);
  color: var(--white-color);
  padding: 10px;
  font-size: 13px;
  border-radius: 5px;
  border: none;
  transition: background-color ease 0.3s;
}

.category:hover {
  background-color: var(--white-color);
  color: var(--green-color);
  border-color: var(--green-color);
}

.autor {
  display: block;
  font-size: 14px;
  color: var(--green-color);
  margin-bottom: 10px;
}

.content {
  position: absolute;
  text-align: left;
  top: 160px;
  background-color: var(--white-color);
  padding: 15px 10px;
  overflow: hidden;
  z-index: 10;
  transition: all 0.3s ease 0.1s;
}

.picture {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: black;
  min-height: 160px;
}

.title {
  color: var(--black-color);
  margin-bottom: 15px;
  font-size: 20px;
}

.text {
  font-size: 13px;
  color: var(--grey-color);
  opacity: 1;
  margin-bottom: 15px;
  transition: all 0.3s ease 0.1s;
}

.button {
  opacity: 0;
  font-size: 14px;
  border-color: var(--grey-color);
  transition: all 0.3s ease 0.1s;
}

.button:hover {
  color: var(--white-color);
  background-color: var(--green-color);
  border-color: var(--green-color);
}

.info {
  position: absolute;
  font-size: 12px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: var(--white-color);
  bottom: 0px;
  padding: 15px 10px;
  z-index: 20;
}

.date,
.views,
.comments {
  position: relative;
  padding-left: 20px;
}

.date::before {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  width: 15px;
  height: 15px;
}

.views::before {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  width: 15px;
  height: 15px;
  background-image: url('./img/eye-solid.svg');
  background-position: center center;
}

.comments::before {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  width: 15px;
  height: 15px;
  background-image: url('./img/comment-solid.svg');
}

.small .date::before,
.big .date::before {
  background-image: url('./img/clock-black.svg');
}

@media (min-width: 1201px) {
  .big .date::before {
    background-image: url('./img/clock-white.svg');
  }

  .big .content {
    top: 250px;
    background-color: transparent;
    color: var(--white-color);
  }

  .big .info {
    background-color: transparent;
    color: var(--white-color);
  }

  .big .title {
    font-size: 25px;
    color: var(--white-color);
  }

  .big .text {
    color: var(--white-color);
  }

  .big .picture {
    height: 100%;
  }
}