.body {
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 30px 0px;
  gap: 30px;
}

.picture {
  flex: 1 0 30%;
}

.content {
  text-align: left;
}

.title {
  color: var(--title-color);
  margin-bottom: 20px;
}

.text {
  font-size: 15px;
  line-height: 2;
}

.text a {
  color: var(--green-color);
}

.text a:hover {
  text-decoration: underline;
}

.autor {
  display: block;
  font-size: 14px;
  color: var(--green-color);
  margin-bottom: 10px;
}

.more {
  display: inline-block;
  border-color: var(--green-color);
  background-color: var(--green-color);
  color: var(--white-color);
  transition: all 0.3s ease 0.1s;
  margin: 30px 0px;
}

.info {
  font-size: 12px;
  display: flex;
  gap: 30px;
  background-color: inherit;
  bottom: 0px;
  padding: 15px 10px;
  z-index: 20;
}

.date,
.views,
.comments {
  position: relative;
  padding-left: 20px;
}

.date::before {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  width: 15px;
  height: 15px;
}

.views::before {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  width: 15px;
  height: 15px;
  background-image: url('./img/eye-solid.svg');
  background-position: center center;
}

.comments::before {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  width: 15px;
  height: 15px;
  background-image: url('./img/comment-solid.svg');
}

.date::before {
  background-image: url('./img/clock-black.svg');
}