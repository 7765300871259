@import "~normalize.css";

@font-face {
  font-family: 'Ubuntu';
  font-display: swap;
  src: url("./fonts/Ubuntu-Bold.woff2") format("woff2"), url("./fonts/Ubuntu-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu';
  font-display: swap;
  src: url("./fonts/Ubuntu-Light.woff2") format("woff2"), url("./fonts/Ubuntu-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu';
  font-display: swap;
  src: url("./fonts/Ubuntu-Regular.woff2") format("woff2"), url("./fonts/Ubuntu-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Ubuntu';
  font-display: swap;
  src: url("./fonts/Ubuntu-Medium.woff2") format("woff2"), url("./fonts/Ubuntu-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}


:root {
  --main-background-color: #FFF;
  --main-text-color: #999;
  --title-color: #000;
  --green-color: #39C59C;
  --grey-color: #888;
  --lightgrey-color: #DDD;
  --darkgrey-color: #333;
  --white-color: #FFF;
  --black-color: #000;
  --lightblue-color: #5BA0D0;
  --yellow-color: #FF0;
}

html {
  position: relative;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  display: flex;
  flex-direction: column;
  min-width: 320px;
  font-family: 'Ubuntu', sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: var(--main-text-color);
  background-color: var(--main-background-color);
}

img {
  max-width: 100%;
  height: auto;
}

button {
  padding: 0;
  cursor: pointer;
}

a {
  text-decoration: none;
  color: inherit;
}

ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
p,
figure,
fieldset {
  margin: 0;
}

iframe {
  border: none;
}

.container {
  max-width: 1230px;
  margin: 0px auto;
  padding: 0px 15px;
  text-align: center;
}

.title {
  font-weight: 700;
}