.news {
  padding: 30px 0px 15px;
  background-color: var(--lightgrey-color);
}

.body {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-bottom: 20px;
}

@media (max-width: 576px) {
  .body {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 769px) {
  .body {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: 'small1 small2 small3'
      'small4 small5 small6';
    gap: 15px;
    margin-bottom: 25px;
  }

  .item:nth-child(1) {
    grid-area: small1;
  }

  .item:nth-child(2) {
    grid-area: small2;
  }

  .item:nth-child(3) {
    grid-area: small3;
  }

  .item:nth-child(4) {
    grid-area: small4;
  }

  .item:nth-child(5) {
    grid-area: small5;
  }

  .item:nth-child(6) {
    grid-area: small6;
  }
}

@media (min-width: 1100px) {
  .body {
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas: 'small1 small2 big1 big1'
      'big2 big2 small3 small4';
    gap: 20px;
    margin-bottom: 30px;
  }

  .item:nth-child(1) {
    grid-area: small1;
  }

  .item:nth-child(2) {
    grid-area: small2;
  }

  .item:nth-child(3) {
    grid-area: big1;
  }

  .item:nth-child(4) {
    grid-area: big2;
  }

  .item:nth-child(5) {
    grid-area: small3;
  }

  .item:nth-child(6) {
    grid-area: small4;
  }
}

.more {
  display: inline-block;
  border-color: var(--green-color);
  background-color: var(--green-color);
  color: var(--white-color);
  transition: all 0.3s ease 0.1s;
  margin: 30px 0px;
}

.more:hover {
  background-color: var(--white-color);
  color: var(--green-color);
}