.select {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media (min-width: 768px) {
  .select {
    flex-direction: row;
  }
}

.selectItem {
  font-size: 14px;
  line-height: 1.5;
  flex: 1 1 33.3333%;
  background-color: var(--white-color);
  border-radius: 10px;
  padding: 10px 15px;
  text-align: center;
}

.itemTitle {
  font-size: 18px;
  color: var(--darkgrey-color);
  font-weight: 700;
}

.listItem {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

.searchResult {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  border-radius: 10px;
  background-color: var(--white-color);
}