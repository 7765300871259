.cell {
  padding: 2px 1px 0px 1px;
  font-size: 14px;
}

.bottomBorder {
  border-bottom: 1px solid var(--grey-color);
}

.rightBorder {
  border-right: 1px solid var(--grey-color);
}

.cell7 {
  grid-column: 8 / 10;
}

.cell8 {
  grid-column: 10 / 12;
}